<template>
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>短视频</el-breadcrumb-item>
                <el-breadcrumb-item>爆款品</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page_headerSecondary">
            <div></div>
            <div class="_add">
                <button @click="openAdd">添加</button>
            </div>
        </div>
        <!-- 列表 -->
        <div class="page_content">
            <el-table :data="tableData" border>
                <el-table-column prop="name" align="center" label="前标题"></el-table-column>
                <el-table-column prop="title" align="center" label="后标题"></el-table-column>
                <el-table-column prop="exposure" align="center" label="视频曝光量"></el-table-column>
                <el-table-column prop="like" align="center" label="视频点赞量"></el-table-column>
                <el-table-column prop="comment" align="center" label="视频评论量"></el-table-column>
                <el-table-column prop="forward" align="center" label="视频转发量"></el-table-column>
                <el-table-column prop="is_top" align="center" label="置顶">
                    <template slot-scope="{ row }">
                        <span :style="{ color: row.is_top ? '#b66dff' : '#fe7c96' }">{{ row.is_top ? "是" : "否" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" align="center" label="状态">
                    <template slot-scope="{ row }">
                        <span :style="{ color: row.status ? '#b66dff' : '#fe7c96' }">{{ row.status ? "显示" : "隐藏" }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="{ row }">
                        <div class="_operate">
                            <button @click="openSet(row)">编辑</button>
                        </div>
                        <div class="_delete">
                            <button @click="del(row)">删除</button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 添加或编辑链接弹框 -->
        <el-dialog
            :itle="form.id ? '编辑' : '添加'"
            :visible.sync="dialogShow"
            width="70%"
            :show-close="false"
            :before-close="bfc"
        >
            <el-form :model="form" label-width="90px">
                <el-form-item label="前标题">
                    <el-input v-model.trim="form.name"></el-input>
                </el-form-item>
                <el-form-item label="后标题">
                    <el-input v-model.trim="form.title"></el-input>
                </el-form-item>
                <el-form-item label="介绍">
                    <el-input type="textarea" autosize resize="none" v-model.trim="form.intro"></el-input>
                </el-form-item>
                <el-form-item label="视频曝光量">
                    <el-input-number v-model="form.exposure" :min="0"></el-input-number>
                </el-form-item>
                <el-form-item label="视频点赞量">
                    <el-input-number v-model="form.like" :min="0"></el-input-number>
                </el-form-item>
                <el-form-item label="视频评论量">
                    <el-input-number v-model="form.comment" :min="0"></el-input-number>
                </el-form-item>
                <el-form-item label="视频转发量">
                    <el-input-number v-model="form.forward" :min="0"></el-input-number>
                </el-form-item>
                <el-form-item label="状态">
                    <el-switch
                        class="sw"
                        v-model="form.status"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="显示"
                        inactive-text="隐藏"
                        :active-value="1"
                        :inactive-value="0"
                    ></el-switch>
                </el-form-item>
                <el-form-item label="置顶">
                    <el-switch
                        class="sw"
                        v-model="form.is_top"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="是"
                        inactive-text="否"
                        :active-value="1"
                        :inactive-value="0"
                    ></el-switch>
                </el-form-item>
                <el-form-item label="拼接图">
                    <upload-img :src="form.montage" @removeSrc="() => (form.montage = '')" ref="montage"></upload-img>
                </el-form-item>
                <el-form-item label="案例图">
                    <div style="display: flex">
                        <upload-img
                            :src="form.first_image"
                            @removeSrc="() => (form.first_image = '')"
                            ref="first_image"
                        ></upload-img>
                        <upload-img
                            :src="form.second_image"
                            @removeSrc="() => (form.second_image = '')"
                            ref="second_image"
                        ></upload-img>
                        <upload-img
                            :src="form.third_image"
                            @removeSrc="() => (form.third_image = '')"
                            ref="third_image"
                        ></upload-img>
                    </div>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogShow = false">取 消</el-button>
                <el-button type="primary" @click="yes">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import uploadImg from "@/components/customs/uploadImg.vue";
import qs from "qs";
export default {
    components: { uploadImg },
    data() {
        return {
            tableData: [],
            form: {},
            dialogShow: false,
        };
    },
    async created() {
        this.tableData = await this.getData();
    },
    methods: {
        async getData() {
            let { data } = await axios.get("/api/video/svclist");
            return data;
        },
        openAdd() {
            this.form = {
                name: "",
                title: "",
                intro: "",
                exposure: 0,
                like: 0,
                comment: 0,
                forward: 0,
                montage: "",
                is_top: 0,
                status: 1,
                first_image: "",
                second_image: "",
                third_image: "",
            };
            this.dialogShow = true;
        },
        openSet(row) {
            this.form = { ...row };
            this.dialogShow = true;
        },
        async yes() {
            let { form } = this;
            form.montage = this.$refs.montage.url;
            form.first_image = this.$refs.first_image.url;
            form.second_image = this.$refs.second_image.url;
            form.third_image = this.$refs.third_image.url;
            if (form.id) {
                // 编辑
                var { data } = await axios.put("/api/video/svcup", qs.stringify(form));
            } else {
                // 添加
                var { data } = await axios.post("/api/video/svcadd", qs.stringify(form));
            }
            this.$message({
                message: data.msg,
                type: data.code == 200 ? "success" : "error",
            });
            this.tableData = await this.getData();
            this.dialogShow = false;
        },
        async del({ id }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => true)
                .catch(() => false);
            if (!s) return;
            let { data } = await axios.delete("/api/video/svcdel", { params: { id } });
            this.$message({
                message: data.msg,
                type: data.code == 200 ? "success" : "error",
            });
            this.tableData = await this.getData();
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
    }
    .page_pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        /deep/.el-pagination {
            border-radius: 50px;
            overflow: hidden;
            padding: 0px;
        }
    }
    .page_headerSecondary {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.sw {
    /deep/.el-switch__label.is-active {
        color: #7781f1 !important;
    }
}
</style>
